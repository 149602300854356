@import 'assets/style/variable.scss';

.mypage {
  display: flex;
  min-height: inherit;
  .main {
    display: flex;
  }
  .contentWrap {
    margin: 0 auto;
    width: 60%;
    margin-top: 40px;
    margin-bottom: 40px;
    .nav {
      display: flex;
      justify-content: space-around;
      border-radius: 10px;
      border: 1px solid #ccc;
      li {
        width: 100%;
        text-align: center;
        font-size: 17px;
        button {
          width: 60%;
          background-color: white;
          padding: 16px 20px;
          font-weight: 700;

          &:hover {
            color: $primary;
            border-bottom: 2px solid $primary;
          }
        }
        .select {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}
//컨테이너
.messageContainer {
  position: relative;
  width: 100%;
  // height: 100vh;
}

.showFormButton {
  background-color: #f2f2f2;
  color: #333;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}