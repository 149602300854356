
.header {
  margin-top: 30px;
  padding: 30px 0 40px 0;
  border-top: 1px solid #cfcccc;
  border-bottom: 1px solid #cfcccc;
  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
.boardContainer {
  &:hover{
    background: #eeeeee;
  }
  .title {
    margin-bottom: 10px;
    font-size:30px;
    font-weight: 500;
  }
  .content {
    margin-bottom: 10px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .nickname{
    color:#797979;
  }
}
.pageContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.notlist {
  margin-top: 20px;
  text-align: center;
}