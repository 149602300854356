.toggleSwitch {
  width: 50px;
  height: 20px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.true {
  background-color: #4caf50;
}

.toggleHandle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

.true .toggleHandle {
  transform: translateX(30px);
}
