@import './function.scss';

.button {
  padding: 50px 0 0;
  background-color: #f5f5f5;
  button {
    width: 100%;
    height: 60px;
    font-size: 17px;
    color: #ffffff;
    background-color: black;
    border: 0;
    border-radius: 10px;
    &:hover {
      background-color: #585858;
    }
    &:active {
      background-color: #000000;
    }
  }
}

.page {
  margin: 0 auto;
  padding: 40px 20px 0px 20px;
  max-width: $content-max-width;
  min-height: calc(100vh - 110px - 95px);
  @include media-mo {
    max-width: calc(100% - 40px);
  }
  &.is-mypage {
    padding-top: 0;
  }
}

.board-page {
  width: 80%;
  margin: 0 auto;
}

.tac {
  text-align: center;
}

.btn-wrap {
  display: flex;
  gap: 6px;
}

.not-list {
  border-bottom: none!important;
  cursor: auto!important;
}

.pointer {
  cursor: pointer;
}

.focus-link {
  cursor: auto;
  scroll-behavior: smooth;
  &:focus {
    outline: none;
    border: none;
  }
}