@import 'assets/style/variable.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  color: white;
  background: black;
  border: 0;
  border-radius: 10px;
  text-align: center;
  gap: 6px;
  white-space: pre;
  &.is_outline {
    background: none;
    border: 1px solid black;
    &:hover {
      background: none;
    }
  }
  &.is_small {
    padding: 0 16px;
    height: 38px;
    font-size: 14px;
    background: #0d6efd;
  }
  &.is_medium {
    height: 48px;
    font-size: 14px;
  }
  &.is_big {
    height: 60px;
    font-size: 16px;
    min-width: 120px;
  }
  &.is_cancle {
    background: black;
  }

  &.is_large {
    height: 50px;
    font-size: 16px;
    min-width: 120px;
  }
  
  &.is_fullWidth{
    width: 100%;
  }

  &:hover {
    background: #585858;
  }
  &:active {
    background: #000000;
  }
  &:disabled{
    background: #585858;
  }
}