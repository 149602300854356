.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.title{
    margin:30px 0px;
    padding: 7px;
    width: 80%;
    font-size:30px;
    border:none;
    box-sizing: border-box;
    &:focus{
      outline:2px solid #000000;
    }
}

.editor{
  width: 80%;
  margin-bottom: 100px;
}

.btnRgn{
  display: flex;
  justify-content: right;
  position:fixed;
  width:100%;
  bottom: 0;
  right:0;
  padding:20px;
  border-top: 1px solid #cfcccc;
  background: #ffffff;
}

.cancel{
  padding: 20px 0;
  margin-right:10px;
  width: 120px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  border: 1px solid #cfcccc;
  &:hover {
      background-color: #585858;
      color: #ffffff;
  }
  &:active {
      background-color: #000000;
      color: #ffffff;
  }
}