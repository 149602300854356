@import 'assets/style/variable.scss';
@import 'assets/style/function.scss';

.main-page {
  article {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    vertical-align: top;
    @include media-mo {
      flex-direction: column-reverse;
    }
    section {
      width: 100%;
    }
  }
  section {
    &:last-child {
      margin-bottom: 40px;
    }
    strong {
      display: block;
      padding: 16px 20px;
      width: 100%;
      background: $secondary;
      color: white;
      border-radius: 16px;
      box-sizing: border-box;
    }
  }
  ul {
    margin-top: 14px;
    max-height: 300px;
    overflow: auto;
    padding-right: 20px;
  }
  li {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    cursor: pointer;
    &:last-child {
      margin-bottom: 20px;
      border-bottom: none;
    }
    p {
      font-weight: 600;
    }
  }
}