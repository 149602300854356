.table {
  margin: 20px 0;
  padding-top: 30px;
  position: relative;

  h2 {
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: bolder;
  }

  p {
    position: absolute;
    top: 40px;
    right: 0px;
    font-size: 11px;
    font-weight: bolder;
    color: #e41a1a;
  }

  .userinfoTable {
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;

      border: 1px solid #000000;
      border-bottom: none;
      border-collapse: collapse;
      box-sizing: border-box;
      background-color: white;

      &:nth-last-child(1) {
        border-bottom: 1px solid black;
      }
      div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        gap: 8px;
      }

      input {
        border-radius: 10px;
        padding: 0px 5px;
        width: 100%;
        height: inherit;
        font-size: 16px;
        border: 1px solid #dfdfdf;

        &::placeholder {
          font-size: 13px;
        }

        &:focus {
          border: 1px solid #99a1ff;
        }
      }
      button {
        height: inherit;
      }
    }

    small {
      //NOTE 스타일 적용
      display: block;
      font-size: 13px;
      color: #f40b0b;

      &.true {
        color: #7ac40c;
      }
    }

  }
}