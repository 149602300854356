@import 'assets/style/function.scss';

.news {
  a {
    display: flex;
    position: relative;
    gap: 12px;
  }
  .imgwrap {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    border: 1px solid #ccc;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content_wrap {
    .title {
      @include multi-dotdotdot(16px, 1);
      max-width: calc(100% - 115px);
      margin-left: 0;
    }
    .content {
      @include multi-dotdotdot(14px, 2);
      margin-top: 10px;
    }
  }
  span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
  }
}