@import 'assets/style/variable.scss';

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  font-size: 25px;
  border-radius: 18px;
  outline: solid 1px #d1d5db;
  &:focus-within {
    outline: solid 2px $primary;
  }
  input {
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding-left: 16px;
    border: none;
    box-sizing: border-box;
    &::placeholder {
      font-size: 14px;
    }
  }
  button {
    transform: translate(0px, 2px);
    background: none;
  }
}