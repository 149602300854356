@import 'assets/style/function.scss';

.footer {
  text-align: center;
  border-top: 1px solid #ccc;
  @include media-mo {
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .copy {
    padding: 38px 0;
    color: #595959;
    font-size: 12px;
  }
}