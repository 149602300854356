.notice_reply {
    margin-top: 100px;
    .title {
      margin-bottom: 20px;
      color: #8b8b8b;
      font-size: 18px;
      font-weight: 600;
    }
    .inputTrue{
      margin-bottom: 40px;
    }
    .inputFalse {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      height: 54px;
      padding-left: 20px;
      width: 100%;
      border: solid 1px #cfcccc;
      border-radius: 8px;
      font-size: 16px;
      color: #8b8b8b;
      box-sizing: border-box;
      cursor: pointer;
    }
    .replies {
      margin-top: 50px;
      padding-top: 40px;
      // border-top: solid 1px #cfcccc;
    }
    :global(.ck-editor__editable) {
      min-height: 200px;
      max-height: 200px;
      // height: 60px;
    }
  }
  .btnRgn {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 10px;
    background: #ffffff;
    .secret{
      margin-right: 10px;
      cursor: pointer;
      font-size: 17px;
    }
    .cancle{
      margin: 0px 10px;
    }
  }
  