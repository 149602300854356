.room-detail {
  .menu {
    display: flex;
    gap: 16px;
  }
  .board {
    display: flex;
    flex-grow: 5;
  }
  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}

.setting {
  display: inline-flex;
  cursor: pointer;
}