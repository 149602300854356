.Button {
  padding: 15px 0;
  width: 100px;
  vertical-align: middle;
  font-size: 17px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid b;
  background-color: #ffffff;
  text-indent: 30px;

  &:hover {
    font-weight: bolder;
  }
}

.snswrap{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 50px;
  .google {
    background: url("google.png");
    background-repeat: no-repeat;
    background-position: left center;
  }
  
  .naver {
    background: url("naver.png");
    background-repeat: no-repeat;
    background-position: left center;
  }
  
  .kakao {
    background: url("kakao.png");
    background-repeat: no-repeat;
    background-position: left center;
  }
}