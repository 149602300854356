.study-room-info {
  position: relative;
  padding-top: 60px;
  .setting {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
  }
  .title {
    position: relative;
    font-size: 28px;
    font-weight: 700;
    .autojoin {
      display: flex;
      position: absolute;
      right: 0;
      top: 10px;
      height: auto;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .maker {
    margin-top: 36px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
  }
  .tag {
    display: flex;
    margin-top: 40px;
    gap: 10px;
    .tags {
      display: inline-flex;
      padding: 3px 10px;
      border-radius: 12px;
    }
  }
  .desc {
    margin-top: 80px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    font-size: 24px;
    color: #434343;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
  }
}