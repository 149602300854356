.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;

    h1 {
            text-align: center;
        }
    
        form {
            display: flex;
            flex-direction: column;
        }
    
        label {
            margin-bottom: 10px;
        }
    
        input[type="text"],
        input[type="email"] {
            padding: 5px;
            margin-bottom: 10px;
        }
    
        input[type="submit"] {
            padding: 10px 20px;
            background-color: #4CAF50;
            color: white;
            border: none;
            cursor: pointer;
        }
    
        input[type="submit"]:hover {
            background-color: #45a049;
        }
}
