.dropdown {
  position: absolute;
  left: -1000%;
  right: -1000%;
  top: 100%;
  margin: 0 auto;
  max-width: 200px;
  width: max-content;
  border: 1px solid #ccc;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  li {
    padding: 4px 8px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    &:hover {
      color: #2f92ff;
    }
  }
}