.board-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    margin-left: auto;
  }
  .instudyroom-board {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    &.is-no-list {
      border: none;
    }
  }
}

.instudyroom-board {
  margin-top: 10px;
}


.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
}

.category {
  display: inline-block;
  margin-bottom: 4px;
  padding: 2px 4px;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  background: #53820a;
}

.etc_wrap {
  display: flex;
  gap: 10px;
  .createDate {
    width: 60px;
    text-align: right;
  }
}