@import 'assets/style/variable.scss';

.container {
  position: relative;
  width: 114px;
  border-radius: 6px;
  background-color: #ffffff;
  align-self: center;
  border: 1px solid #ccc;
  &.is_open {
    border-radius: 6px 6px 0 0;
  }
  .selectedText {
    padding: 8px 30px 8px 10px;
    font-size: 14px;
    margin-left: 5px;
    white-space: pre;
    cursor: pointer;
  }
  .icon{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    transition: all 0.2s;
    &.is_open {
      transform: rotate(180deg);
      transition: all 0.2s;
    }
  }
}
.selectBox {
  position: absolute;
  top: 100%;
  left: -1px;
  width: 100%;
  overflow: hidden;
  border-radius: 0px 0px 6px 6px;
  color: black;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1;
  li {
    padding: 12px 0px 12px 12px;
    font-size: 15px;
    transition: background-color 0.2s ease-in;
    background: #fff;
    cursor: pointer;
    &:hover {
      // background-color: #595959;
      color: $primary;
      font-weight: 600;
    }
  }
}
