// 폼
.messageForm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  right: 0;
  width: 100%;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
}

// 입력 필드
.messageInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

// 버튼
.messageButton {
  width: 120px;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.messageButton:hover {
  background-color: #45a049;
}

.messageButton:active {
  background-color: #3e8e41;
}

.messageButton:disabled {
  background-color: #869a86;
}
