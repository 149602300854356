
.typechange {
  margin-left: 8px;
  cursor: pointer;
}



// @media(max-width:676px) {

//   .loginli {
//     &.Id {
//       padding: 15px 3.69822485207%;
//     }

//     &.Pw {
//       padding: 15px 2.51479289941%;
//     }
//   }

//   .input {
//     width: 39.94082840237%;
//   }

//   .small {
//     padding-left: 2.95857988166%;
//   }
// }