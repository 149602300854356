.img_wrap {
  position: relative;
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  cursor: pointer;

  &.is_big {
    margin: 20px auto;
    width: 220px;
    height: 220px;
  }
  &.is_border {
    border: 1px solid #1b67ff;
  }
  &.regist_page {
    width: 220px;
    height: 220px;
    border-radius: 16px;
    margin: 0 auto 20px auto;
    img {
      width: 50%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: initial;
      &.is_select {
        width: 100%;
      }
    }
  }

  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    padding: 0;

    cursor: pointer;
    opacity: 0;
  }

  img, .img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}