@import "assets/style/function.scss";

.header {
  margin-top: 30px;
  padding: 30px 0px;
  border-top: 1px solid #cfcccc;
  border-bottom: 1px solid #cfcccc;
  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}

.boardContainer {
  .title {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
  }
  .content {
    margin-bottom: 10px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .nickname {
    color: #797979;
  }
}
.pageContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.studyroom {
  .list {
    display: grid;
    width: 100%;
    margin: 40px auto 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    &.is_not_list {
      display: block;
    }
  }
  .card_list {
    position: relative;
    height: 290px;
    padding: 20px;
    border-radius: 30px;
    border: 2px solid #d1d1d1;
    box-sizing: border-box;
    cursor: pointer;
    @include media-mo {
      height: 250px;
    }
    .title {
      @include multi-dotdotdot(24px, 2);
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 500;
      @include media-mo {
        font-size: 20px;
      }
    }
    .autojoin {
      display: flex;
      justify-content: flex-end;
    }
    .tag {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      span {
        padding: 3px 10px;
        height: 22px;
        background: #f2f4f8;
        border-radius: 15px;
        font-size: 13px;
        color: #3e86f5;
        font-weight: 700;
      }
    }
    .info {
      display: flex;
      position: absolute;
      width: calc(100% - 40px);
      bottom: 20px;
      align-items: center;
      justify-content: space-between;
    }
    .icon {
      display: flex;
      align-items: center;
      svg {
        transform: translateY(2px);
        margin-right: 4px;
      }
    }
  }
}

.lock {
  position: absolute;
  right: 20px;
  top: 30px;
}
