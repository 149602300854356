@import 'assets/style/variable.scss';

.sidebackground {
  // z-index: -1;
  // position: fixed;
  // background-color: #f3f3f3;
  // max-width: 300px;
  // width: 35%;
  // height: 100%;
  // top: 0;
}

.side {
  max-width: 300px;
  width: 100%;
  padding-right: 20px;
  // background-color: #f3f3f3;
  border-right: 1px solid #f3f3f3;

  .nav {
    margin-left: 0;
    padding-left: 0;

    li {
      margin-bottom: 2px;
      border-radius: 4px;
      padding: 10px 20px 10px 14px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #e5e7eb;
      }
      &.is-active {
        background-color: #e5e7eb;
      }
    }
  }
}
