@import 'assets/style/variable.scss';

.contentwrap {

  .userdata {
    padding: 10px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
    }

    .title {
      cursor: pointer;
    }
  }


  .title {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 800;
    left: 0;
  }

  .content {
    left: 0;
    margin-bottom: 10px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nickname {
    left: 0;
    color: #797979;
  }
}

.list {
  margin: 10px 0;
}

.userdata {
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid #d2d4d9;

  .title {
    display: flex;
    justify-content: space-between;

    span {
      color: #00a9dc;
    }
  }
}

.text_wrap {
  width: 100%;
  padding: 8px 0 10px 0;

  .content {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
  }

  .img_wrap {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 2px;
    margin: auto;

    display: block;
    width: 40px;
    height: 40px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.viewCount {
  display: inline-block;
  margin-right: 10px;
  padding: 1px 10px 2px 10px;
  border-radius: 10vw;
  font-size: 14px;
  color: #585858;
  background: white;
  border: 1px solid #585858;
}

// .secret {
//   position: absolute;
//   top: 0;
//   right: 0;
// }

.contentWrap {
  margin: 0 auto;
  width: 60%;
  margin-top: 40px;
  margin-bottom: 40px;

  .userProfile {
    display: grid;
    place-items: center;
    // margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid #ccc;
    overflow: hidden;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    border: 1px solid #ccc;
    white-space: pre;

    li {
      width: 100%;
      text-align: center;
      font-size: 17px;

      button {
        width: 60%;
        background-color: white;
        padding: 16px 20px;
        font-weight: 700;

        &:hover {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
}