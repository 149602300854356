  .description {
    margin: 0 auto;
    input {
      margin-bottom: 20px;
      width: calc(100% - 10px);
      height: 50px;
      // padding: 10px;
    }
  }

  .typechange {
    display: inline-block;
    cursor: pointer;
  }

  .delete {
    .deletgaider {
      margin: 50px auto;
      padding: 30px;
      // width: 350px;
      border-radius: 10px;
      background: #ececec;
      box-sizing: border-box;
      font-size: 12px;
    }
  }

  .private {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18p;
    gap: 8px;
    + p {
      text-align: center;
      color: #848484;
      font-size: 14px;
    }
  }