@import 'assets/style/variable.scss';

.container {
  position: relative;
  padding: 0px 0px 100px 0px;
  header {
    .userInfoContainer{
      display:flex;
      align-items: center;
    }
    .profile {
      width:50px;
      height:50px;
      border-radius: 50%;
      margin-right:10px;
      overflow: hidden;
      border: 1px solid #ccc;
    }
    .nick {
      position: relative;
      font-size: 25px;
      color: black;
    }
    .info {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 500;
        color: #8b8b8b;
      }
      .report{
        margin-left:10px;
        color: #cc4b4b;
        cursor: pointer;
      }
    }
    .title {
      margin-top: 40px;
      font-size: 30px;
      font-weight: 600;
    }
    .button_wrap {
      position: absolute;
      display: flex;
      top: 10px;
      right: 10px;
      gap: 10px;
    }
    .info {
      span {
        font-size: 16px;
        font-weight: 500;
        color: #8b8b8b;
      }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    .content {
      padding: 20px 0px 50px 0px;
      font-size: 16px;
      border-bottom:solid 1px #cfcccc;
    }
    .contentImage{
      width:50%;      
    }
  }
  .btnCancle{
    background: #cfcccc;
    color:#969696
  }

  .countContainer {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    gap: 10px;
    .btn {
      color: red;
    }
  }
}
