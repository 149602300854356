//테이블 스타일

// small {
//   display: block;
//   font-size: 13px;
//   color: #f40b0b;

//   &.true {
//     color: #7ac40c;
//   }
// }
.headername {
  p {
    font-size: 30px;
    font-weight: 700;
  }
}

.gaider {
  margin: 50px auto 10px auto;
  padding: 10px;
  width: 350px;
  border-radius: 10px;
  background: #ececec;
  box-sizing: border-box;
  font-size: 12px;
}

.tagalign {
  margin: 20px 0;

  .tags {
    align-items: center;
    width: 100%;

    span {
      display: inline-block;
      margin-right: 10px;
      padding: 1px 10px 2px 10px;
      border-radius: 10vw;
      font-size: 14px;
      color: #585858;
      background: white;
      border: 1px solid #585858;
      cursor: pointer;

      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 10px;
      }

      &.is_select {
        color: white;
        background: #585858;
        // border-color: 
      }
    }
  }
}

.prople {
  margin: 20px auto;
  height: 200px;
  width: 200px;
  border-radius: 30px;

  .imgwrap {
    border: 2px solid #d1d1d1;
    position: relative;
    height: 200px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;

    input[type="file"] {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0;
    }
  }

  img {
    position: absolute;
    // top: -1000%;
    // right: -1000%;
    // bottom: -1000%;
    // left: -1000%;
    // margin: auto;
    // height: 100%;
    // padding: 20px;
    // width: 100%;
    // height: 220px;
    // box-sizing: border-box;
  }
}

.description {
  input {
    margin-bottom: 20px;
    width: 97%;
    height: 50px;
    padding: 10px;
  }
}

.typechange {
  display: inline-block;
  cursor: pointer;
}