@import "assets/style/function.scss";

.form {
  margin: 0 auto;
  padding: 60px 40px 40px 40px;
  // width: 70%;
  margin-top: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  max-width: 800px;

  background-color: #f5f5f5;
  border: 1px solid #adb5b5;
  &.nomargin {
    margin: 0;
  }

  h2 {
    margin: 0 auto;
    font-weight: bold;
    font-size: 25px;
  }
}

.gray {
}

.form-user-guide {
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 80px;
  text-align: center;
  background-color: #ffffff;

  li {
    list-style: none;
    padding: 5px;
    display: inline-block;
  }
}
