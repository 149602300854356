// @import '../../assets/style/function.scss';
.label {
  display: inline-block;
  background-color: #ffffff;
  font-weight: bolder;
  min-width: 110px;
  white-space: pre;
  @media (max-width: 600px) {
    display: none;
  }
}

.isRequire {
  color: #f11616;
}