@import 'assets/style/variable.scss';
@import 'assets/style/function.scss';

.noti {
  display: none;
  position: absolute;
  top: calc(100%);
  right: 0;
  left: auto;
  width: 350px;
  margin: auto;
  background: white;
  color: black;
  border-radius: 8px;
  border: 1px solid #ccc;
  z-index: 1;
  cursor: auto;

  max-height: 250px;
  overflow: auto;
  &.is_show {
    display: block;
  }
  .tab {
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;
    .nav {
      cursor: pointer;
      color: #ccc;
      &.is_select {
        color: black;
      }
    }
  }
  .title {
    position: sticky;
    top: 0;
    background: white;
    padding: 6px 10px;
    font-size: 14px;
    z-index: 1;
    .un_read {
      color: $primary;
      font-size: 16px;
      font-weight: 600;
    }
    .all_read {
      position: absolute;
      right: 6px;
      top: 8px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  li {
    position: relative;
    font-size: 14px;
    border-top: 1px solid #eee;
    &.not_alarm {
      padding: 10px;
    }
    &.is_read {
      .noti_link {
        background: #fff;
        color: #b7b7b7!important;
        padding-right: 40px;
      }
    }
    .noti_link {
      padding: 10px;
      background: #eee;
      @include multi-dotdotdot(14px, 2);
      padding-right: 110px;
      cursor: pointer;
    }
    .ctl {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;

      display: flex;
      gap: 10px;
      margin: auto;
      height: fit-content;
      font-size: 12px;
      span {
        cursor: pointer;
        &:hover {
          color: $primary
        }
      }
    }
  }
}