@import "assets/style/function.scss";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  > :not(.dim) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    min-width: 200px;
    min-height: 200px;

    background: white;
    @include media-mo {
      min-width: auto;
      max-width: 80%;
    }
  }
  .modal-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  .dim {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: inline-flex;
    cursor: pointer;
  }
}

.modal-buttons {
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: right;
  gap: 8px;
}
