.studyroom-setting-modal {
  border: 1px solid #ccc;
  .tags {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    span {
      display: inline-block;
      margin-right: 10px;
      padding: 1px 10px 2px 10px;
      border-radius: 10vw;
      font-size: 14px;
      color: #585858;
      background: white;
      border: 1px solid #585858;
      cursor: pointer;
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 10px;
      }
      &.is-select {
        color: white;
        background: #585858;
        // border-color: 
      }
    }
  }
  .ck-editor__editable {
    min-height: 200px;
  }
}