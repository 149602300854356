@import 'assets/style/function.scss';

.message_box {
  max-height: 500px;
  overflow-y: auto;
  @include blind-scroll();
}

.message_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: #f5f5f5;
}

// 쪽지 리스트
.messagelist {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

// 쪽지 항목
.messageitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.messageitem:last-child {
  margin-bottom: 0;
}

.messageitem .sender {
  font-weight: bold;
}

.messageitem .timestamp {
  font-size: 0.8rem;
  color: #888;
}

.messageitem .content {
  margin-top: 5px;
}

.messageitem .content p {
  margin: 0;
}

// 버튼
.messagebutton {
  width: 120px;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.messagebutton:hover {
  background-color: #45a049;
}

.messagebutton:active {
  background-color: #3e8e41;
}