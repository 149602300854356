// 폼
.messageForm {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  width: 300px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.is_modal {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    box-shadow: none;
    margin-top: 30px;
  }
}

// 입력 필드
.messageInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

// 버튼
.messageButton {
  width: 120px;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.messageButton:hover {
  background-color: #45a049;
}

.messageButton:active {
  background-color: #3e8e41;
}

.messageButton:disabled {
  background-color: #869a86;
}
