.container {
  list-style: none;
  text-align: center;
  border-radius: 3px;
  padding: 1px;
  li {
    display: inline-block;
    padding: 5px;
    margin: 5px;
    width: 25px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 17px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: black;
    }
    &:focus::after {
      color: white;
      background-color: black;
    }
    &.is_select {
      background: black;
      color: white;
    }
  }
  span {
    &:hover::after,
    &:focus::after {
      border-radius: 100%;
      color: white;
      background-color: black;
    }
  }
}
