.userinfowrap {
    position: relative;
}

.nick {
  position: relative;
  cursor: pointer;
  &.is_detail {
    font-size: 25px;
    font-weight: 500;
  }
  &.is_reply {
    font-size: 20px;
    font-weight: 500;
  }
}