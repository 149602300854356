.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  label {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
}

.wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
}

.title {
  margin: 30px 0px;
  padding: 7px;
  width: 100%;
  font-size: 30px;
  border: none;

  &:focus {
    outline: 2px solid #000000;
  }
}

.control_wrap {
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 20px;
  width: 100%;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.tags {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px 0px;
  span {
    display: inline-block;
    margin-right: 10px;
    padding: 1px 10px 2px 10px;
    border-radius: 10vw;
    font-size: 14px;
    color: #585858;
    background: white;
    border: 1px solid #585858;
    cursor: pointer;
    &:first-child {
      margin-left: 20px;
    }
    &:last-child {
      margin-right: 10px;
    }
    &.is_select {
      color: white;
      background: #585858;
      // border-color: 
    }
  }
}

.editor {
  width: 100%;
  margin-bottom: 100px;
}

.btnRgn {
  display: flex;
  justify-content: right;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 20px;
  border-top: 1px solid #cfcccc;
  background: #ffffff;
}

.cancel {
  padding: 20px 0;
  margin-right: 10px;
  width: 120px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 17px;
  border: 1px solid #cfcccc;

  &:hover {
    background-color: #585858;
    color: #ffffff;
  }

  &:active {
    background-color: #000000;
    color: #ffffff;
  }
}